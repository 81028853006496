import React, { useEffect, useState } from "react";
import "./personalDetail.scss";
import {
  Col,
  Row,
  message,
  Button,
  Typography,
  Checkbox,
  Input,
  Space,
  Divider,
  DatePicker,
  Modal,
  Select,
} from "antd";
import moment from "moment";
import certificate from "../../assets/images/certificate.png";
import rightToWork from "../../assets/images/rightToWork.png";

import shield from "../../assets/images/shield.png";
import Spinner from "../spinner/Spinner";
import * as service from "../../pages/main/mainServices";
import { getAuth } from "firebase/auth";
import config from "../../config.json";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { log } from "../../utils/helper/Utils";
import dayjs from "dayjs";
import GoogleMapAutoComplete from "../GoogleMapAutoComplete/GoogleMapAutoComplete";
import ServerMessage from "../ServerMessage/ServerMessage";

function PersonalDetail({ props, data, ip }) {
  const [loading, setLoading] = useState(false);
  const { Paragraph } = Typography;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [checked, setChecked] = useState(false);
  const [agreementInput, setAgreementInput] = useState("");
  const storage = getStorage();
  const [policeCheckUrl, setPoliceCheckUrl] = useState("");
  const [rightToWorkUrl, setRightToWorkUrl] = useState("");
  const [firstName, setFirstName] = useState(data.given_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [middleName, setMiddleName] = useState(data.middle_name);
  const [birthDate, setBirthDate] = useState(data.birth_date);
  const [gender, setGender] = useState(data.gender);
  const [addressData, setAddressData] = useState();

  useEffect(() => {
    getPoliceCheckImage(data && data.police_doc_url ? data.police_doc_url : "");
    getRightToWorkImage(
      data && data.right_work_doc_url ? data.right_work_doc_url : ""
    );
  }, []);

  const getRightToWorkImage = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setRightToWorkUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const getPoliceCheckImage = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setPoliceCheckUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const onBirthDataChange = (date, dateString) => {
    setBirthDate(dateString);
  };

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch { }
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() { },
    });
  };

  const handleServerSucessModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      let baseUrl = window.localStorage.getItem(config.riderLandingpageUrl);
      var win = window.open(baseUrl, "_self");
      win.focus();
    } catch { }
  };

  const modalSuccessInfo = (message) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk();
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    if (firstName.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "First Name is required",
      });
      return;
    }
    if (lastName.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "Last Name is required",
      });
      return;
    }

    var isAuto = false;

    if (addressData !== undefined && addressData.length === 9) {
      isAuto = addressData[8];

      var state = addressData[2];
      if (state.trim() === "") {
        messageApi.open({
          type: "error",
          content: "State Type is a mandatory field",
        });
        return;
      }

      var regionArea = addressData[3];
      if (regionArea.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Area Name is a mandatory field",
        });
        return;
      }

      var streetName = addressData[4];
      if (streetName.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Street Name is a mandatory field",
        });
        return;
      }

      var streetNumber = addressData[5];
      if (streetNumber.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Street Number is a mandatory field",
        });
        return;
      }

      var postCode = addressData[7];
      if (postCode.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Post Code is a mandatory field",
        });
        return;
      }

      var streetType = addressData[6];
      if (!isAuto) {
        if (streetType.trim() === "") {
          messageApi.open({
            type: "error",
            content: "Street Type is a mandatory field",
          });
          return;
        }
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Something bad happened to address",
      });
    }
    setLoading(true);
    try {
      const result = await service.documentsPortalUpdateNCC(
        firstName,
        lastName,
        middleName,
        birthDate,
        gender,
        addressData[1],
        addressData[0],
        addressData[5],
        addressData[4],
        addressData[6],
        addressData[3],
        addressData[2],
        addressData[7],
        "AU",
        agreementInput,
        ip
      );
      setLoading(false);

      switch (result.data.code) {
        case 200:
          modalSuccessInfo("Application updates received");
          break;
        case 428:
          modalErrorInfo(result.data.message);
          break;
        case 429:
          modalErrorInfo(result.data.message);
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err,
      });
      setLoading(false);
    }
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const onAddressDataChange = (value) => {
    setAddressData(value);
  };

  const disabledDate = (current) => {
    const minimumAge = moment().subtract(18, "years");
    return current && current > minimumAge.startOf("day");
  };

  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main>
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section className="personal-detail">
              <Row justify="center" align="middle" gutter={[24, 16]}>
                <Divider className="divider">Personal Details</Divider>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <div className="componnet-wrapper-certificate">
                    <img className="certificate-img" src={rightToWork} />
                    <Button
                      className="certificate-btn"
                      onClick={() => {
                        var win = window.open(rightToWorkUrl, "_blank");
                        win.focus();
                      }}
                    >
                      Right To Work
                    </Button>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <div className="componnet-wrapper-certificate">
                    <img className="certificate-img" src={shield} />
                    <Button
                      className="certificate-btn"
                      onClick={() => {
                        var win = window.open(policeCheckUrl, "_blank");
                        win.focus();
                      }}
                    >
                      Police Check
                    </Button>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Typography.Text>
                    Your personal details including Full Name, DOB, Gender and
                    Residential Address must match the ones you have used for
                    your Police Check and Right to Work application.
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Please make sure that bellow details are completely in-line
                    with above reports.
                  </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>First Name:</Typography.Text>
                    <Input
                      size="large"
                      status={firstName.trim().length < 2 && "error"}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Middle Name:</Typography.Text>
                    <Input
                      size="large"
                      value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Last Name:</Typography.Text>
                    <Input
                      size="large"
                      status={lastName.trim().length < 2 && "error"}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Date of Birth:</Typography.Text>
                    <DatePicker
                      allowClear={false}
                      disabledDate={disabledDate}
                      onChange={onBirthDataChange}
                      defaultValue={dayjs(birthDate, "YYYY-MM-DD")}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Gender:</Typography.Text>
                    <Select
                      defaultValue={gender}
                      onChange={handleGenderChange}
                      options={[
                        {
                          value: "Male",
                          label: "Male",
                        },
                        {
                          value: "Female",
                          label: "Female",
                        },
                        {
                          value: "Non-binary",
                          label: "Non-binary",
                        },
                        {
                          value: "Prefer not to say",
                          label: "Prefer not to say",
                        },
                      ]}
                    />
                  </Space.Compact>
                </Col>
                <GoogleMapAutoComplete
                  data={data.residential_address_segmented}
                  label="Residential Address:"
                  onAddressDataChange={onAddressDataChange}
                />
              </Row>
            </section>
          </Col>
        </Row>
      </main>
      <div className="user-footer">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph
              className="policy-agreement"
              align="justify"
              target="_blank"
            >
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)}>
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 && checked && (
                <Button
                  className="submit"
                  type="primary"
                  onClick={() => handleSubmitPage()}
                >
                  Sign and Submit
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default PersonalDetail;
