import React, { useEffect, useState } from "react";
import "./nccSettings.scss";
import {
  Col,
  Row,
  message,
  Button,
  Typography,
  Checkbox,
  Input,
  Space,
  Divider,
  DatePicker,
  Modal,
} from "antd";
import moment from "moment";
import Spinner from "../spinner/Spinner";
import * as service from "../../pages/main/mainServices";
import { getAuth } from "firebase/auth";
import config from "../../config.json";
import dayjs from "dayjs";
import ServerMessage from "../ServerMessage/ServerMessage";
import { log } from "../../utils/helper/Utils";

function ThirdNccSetting({ props, data, ip }) {
  const [loading, setLoading] = useState(false);
  const { Paragraph } = Typography;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [checked, setChecked] = useState(false);
  const [agreementInput, setAgreementInput] = useState("");
  const [firstName, setFirstName] = useState(data.given_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [middleName, setMiddleName] = useState(data.middle_name);
  const [birthDate, setBirthDate] = useState(data.birth_date);

  useEffect(() => {}, []);

  const onBirthDataChange = (date, dateString) => {
    setBirthDate(dateString);
  };

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch {}
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleServerSucessModalOk = (url) => {
    try {
      const auth = getAuth();
      auth.signOut();
      var win = window.open(url, "_self");
      win.focus();
    } catch {}
  };

  const modalSuccessInfo = (message, url) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk(url);
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    if (firstName.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "First Name is required",
      });
      return;
    }
    if (lastName.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "Last Name is required",
      });
      return;
    }

    setLoading(true);
    try {
      const result =
        await service.documentsPortalNccNameUpdateAndNewFullNccCheck(
          firstName,
          lastName,
          middleName,
          birthDate,
          agreementInput,
          ip
        );
      setLoading(false);
      log(
        "************documentsPortalNccNameUpdateAndNewFullNccCheck ************** 141"
      );
      log(result.data);
      switch (result.data.code) {
        case 200:
          modalSuccessInfo(
            "Application updates received",
            result.data.continue_url
          );
          break;
        case 428:
          modalErrorInfo(result.data.message);
          break;
        case 429:
          modalErrorInfo(result.data.message);
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err,
      });
      setLoading(false);
    }
  };

  const disabledDate = (current) => {
    const minimumAge = moment().subtract(18, "years");
    return current && current > minimumAge.startOf("day");
  };

  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main>
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section className="ncc-setting-header">
              <Row justify="center" align="middle" gutter={[24, 16]}>
                <Divider className="divider">
                  Your provided check has not been accepted.
                </Divider>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Typography.Text>
                    To proceed, you will need to start a new check.
                  </Typography.Text>
                  <br />
                  <br />
                  <Typography.Text>
                    Bearer partners with “National Crime Check Pty Ltd” for an
                    easy and secure online solution for Identity Verification,
                    Right to Work in Australia and Criminal History Check.
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Start a New NCC Check (Fees may apply)
                  </Typography.Text>
                  <br />
                  <br />
                  <Typography.Text>
                    The check may incur costs, which you will be responsible for
                    covering. This cost will be charged directly by National
                    Crime Check (NCC) for conducting the required Background and
                    Right to Work checks.
                  </Typography.Text>
                  <br />
                  <br />
                  <Typography.Text>
                    Providing false or inaccurate details might cause delays or
                    even unsuccessful check results. By either of having
                    unsuccessful check outcomes or incomplete checks, you will
                    not be able to complete the onboarding process as a Bearer
                    service provider and delays for assessing your application
                    could be expected.
                  </Typography.Text>
                  <br />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>First Name:</Typography.Text>
                    <Input
                      size="large"
                      status={firstName.trim().length < 2 && "error"}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Middle Name:</Typography.Text>
                    <Input
                      size="large"
                      value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Last Name:</Typography.Text>
                    <Input
                      size="large"
                      status={lastName.trim().length < 2 && "error"}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Date of Birth:</Typography.Text>
                    <DatePicker
                      allowClear={false}
                      disabledDate={disabledDate}
                      onChange={onBirthDataChange}
                      defaultValue={dayjs(birthDate, "YYYY-MM-DD")}
                    />
                  </Space.Compact>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </main>
      <div className="user-footer ncc-setting">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph
              className="policy-agreement"
              align="justify"
              target="_blank"
            >
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)}>
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <Paragraph
              align="justify"
              target="_blank"
              className="policy-agreement"
            >
              I hereby give the permission to Bearer to:
              <ol>
                <li>
                  {" "}
                  Send my basic personal information to NCC™ and InstaID™ for ID
                  Verification, Right-to Work and Criminal History Check
                  purposes.
                </li>
                <li>
                  Run an assessment to decide about my eligibility to work as
                  ‘Bearer Service Provider’ based on the feedback provided by
                  Bearer’s legal partners.
                </li>
              </ol>
              To start the process, please click bellow button, follow the
              prompts and complete the forms to have the checks done.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 && checked && (
                <Button
                  className="submit"
                  type="primary"
                  onClick={() => handleSubmitPage()}
                >
                  Sign and Submit
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default ThirdNccSetting;
