import React, { useEffect, useState } from "react";
import "./nccSettings.scss";
import {
  Col,
  Row,
  message,
  Button,
  Typography,
  Checkbox,
  Input,
  Space,
  Divider,
  DatePicker,
  Modal,
  Radio,
} from "antd";

import Spinner from "../spinner/Spinner";
import * as service from "../../pages/main/mainServices";
import { getAuth } from "firebase/auth";
import config from "../../config.json";
import dayjs from "dayjs";
import ServerMessage from "../ServerMessage/ServerMessage";
import { log } from "../../utils/helper/Utils";
import UploadNccFileSetting from "./UploadNccFileSetting";

function FifthNccSetting({ props, data, ip }) {
  const [loading, setLoading] = useState(false);
  const { Paragraph } = Typography;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [checked, setChecked] = useState(false);
  const [agreementInput, setAgreementInput] = useState("");
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [onRightToWorkUrl, setOnRightToWorkUrl] = useState("");
  const [docDateIssue, setDocDateIssue] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [radioValue, setRadioValue] = useState(1);

  useEffect(() => {}, []);

  const handleOnRightToWorkUrl = (value) => {
    setOnRightToWorkUrl(value);
    log(value);
  };

  const onDataChange = (date, dateString) => {
    setDocDateIssue(dateString);
  };

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch {}
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleServerSucessModalOk = (url) => {
    try {
      const auth = getAuth();
      auth.signOut();
      var win = window.open(url, "_self");
      win.focus();
    } catch {}
  };

  const modalSuccessInfo = (message, url) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk(url);
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    if (radioValue === 2) {
      if (onRightToWorkUrl.length < 2) {
        messageApi.open({
          type: "error",
          content: "Please upload new file",
        });
        return;
      }

      if (firstCode.trim().length < 2) {
        messageApi.open({
          type: "error",
          content: "Code 1 is required",
        });
        return;
      }
      if (secondCode.trim().length < 2) {
        messageApi.open({
          type: "error",
          content: "Code 2 is required",
        });
        return;
      }
    }
    const functionName =
      radioValue === 1
        ? config.documentsPortalNccNewRightToWork
        : config.documentsPortalNccUploadRightToWork;

    setLoading(true);
    try {
      const result = await service.UploadRightToWorkOrNccNewRightToWork(
        firstCode,
        secondCode,
        onRightToWorkUrl,
        docDateIssue,
        agreementInput,
        ip,
        functionName
      );
      setLoading(false);
      log(
        "************UploadPoliceCheckOrNccNewPoliceCheck ************** 158"
      );
      log(result.data);
      switch (result.data.code) {
        case 200:
          const url =
            functionName === config.documentsPortalNccNewRightToWork
              ? result.data.continue_url
              : window.localStorage.getItem(config.riderLandingpageUrl);
          modalSuccessInfo("Application updates received", url);
          break;
        case 428:
          modalErrorInfo(result.data.message);
          break;
        case 429:
          modalErrorInfo(result.data.message);
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err,
      });
      setLoading(false);
    }
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main>
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section className="ncc-setting-header">
              <Row justify="center" align="middle" gutter={[24, 16]}>
                <Divider className="divider">
                  Your provided check has not been accepted.
                </Divider>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Typography.Text>
                    To proceed, you will need to start a new check.
                  </Typography.Text>
                  <br />
                  <br />
                  <Typography.Text>
                    Bearer partners with “National Crime Check Pty Ltd” for an
                    easy and secure online solution for Identity Verification,
                    Right to Work in Australia and Criminal History Check.
                  </Typography.Text>
                  <br />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                  <Radio.Group onChange={onRadioChange} value={radioValue}>
                    <Radio className="ncc-radio" value={1}>
                      Start a New NCC Check (Fees may apply)
                    </Radio>
                    <Typography.Text
                      className={radioValue === 2 && "ncc-content-disable"}
                    >
                      <br />
                      The check may incur costs, which you will be responsible
                      for covering. This cost will be charged directly by
                      National Crime Check (NCC) for conducting the required
                      Background and Right to Work checks.
                      <br />
                      Providing false or inaccurate details might cause delays
                      or even unsuccessful check results. By either of having
                      unsuccessful check outcomes or incomplete checks, you will
                      not be able to complete the onboarding process as a Bearer
                      service provider and delays for assessing your application
                      could be expected.
                    </Typography.Text>
                    <br />
                    <Radio className="ncc-radio" value={2}>
                      Upload an Existing NCC Report (Longer processing time)
                    </Radio>
                    <br />
                    <Typography.Text
                      className={radioValue === 1 && "ncc-content-disable"}
                    >
                      If you have a recent Right to Work Check report, make sure
                      it:
                      <ol>
                        <li>Was issued by National Crime Check (NCC).</li>
                        <li>Was issued within the last 60 days.</li>
                        <li>
                          Matches the exact name used in this application form.
                        </li>
                      </ol>
                    </Typography.Text>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="center"
                align="middle"
                gutter={[24, 16]}
                className={radioValue === 1 && "ncc-content-disable"}
              >
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Code 1:</Typography.Text>
                    <Input
                      size="large"
                      status={firstCode.trim().length < 2 && "error"}
                      value={firstCode}
                      onChange={(e) =>
                        setFirstCode(e.target.value.toUpperCase())
                      }
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Code 2:</Typography.Text>
                    <Input
                      size="large"
                      status={secondCode.trim().length < 2 && "error"}
                      value={secondCode}
                      onChange={(e) =>
                        setSecondCode(e.target.value.toUpperCase())
                      }
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Document Issue Date:</Typography.Text>
                    <DatePicker
                      allowClear={false}
                      onChange={onDataChange}
                      defaultValue={dayjs(docDateIssue, "YYYY-MM-DD")}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Typography.Text>
                    Certificate file (PDF Only):
                  </Typography.Text>
                  <UploadNccFileSetting onfileUrl={handleOnRightToWorkUrl} />
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </main>
      <div className="user-footer ncc-setting">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph
              className="policy-agreement"
              align="justify"
              target="_blank"
            >
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)}>
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <Paragraph
              align="justify"
              target="_blank"
              className="policy-agreement"
            >
              I hereby give the permission to Bearer to:
              <ol>
                <li>
                  {" "}
                  Send my basic personal information to NCC™ and InstaID™ for ID
                  Verification, Right-to Work and Criminal History Check
                  purposes.
                </li>
                <li>
                  Run an assessment to decide about my eligibility to work as
                  ‘Bearer Service Provider’ based on the feedback provided by
                  Bearer’s legal partners.
                </li>
              </ol>
              To start the process, please click bellow button, follow the
              prompts and complete the forms to have the checks done.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 && checked && (
                <Button
                  className="submit"
                  type="primary"
                  onClick={() => handleSubmitPage()}
                >
                  Sign and Submit
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default FifthNccSetting;
