import React, { useEffect, useState } from "react";
import Spinner from "../spinner/Spinner";
import {
  Col,
  Row,
  message,
  Button,
  Typography,
  Checkbox,
  Input,
  Space,
  Divider,
  Modal,
} from "antd";
import { log } from "../../utils/helper/Utils";
import config from "../../config.json";
import ServerMessage from "../ServerMessage/ServerMessage";
import profile from "../../assets/images/profile.png";
import "./reject.scss";
import certificate from "../../assets/images/certificate.png";
import shield from "../../assets/images/shield.png";
import rightToWork from "../../assets/images/rightToWork.png";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { getAuth } from "firebase/auth";
import * as service from "../../pages/main/mainServices";

function Reject({ props, data, ip }) {
  const [loading, setLoading] = useState(false);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [checked, setChecked] = useState(false);
  const { Paragraph } = Typography;
  const [agreementInput, setAgreementInput] = useState("");
  const [policeCheckUrl, setPoliceCheckUrl] = useState("");
  const [rightToWorkUrl, setRightToWorkUrl] = useState("");
  const [insuranceUrl, setInsuranceUrl] = useState("");
  const [avatar, setAvatar] = useState("");
  const [idCard, setIdCard] = useState("");
  const [rejectInfo, setRejectInfo] = useState("");
  const [newCertificateUrl, setNewCertificateUrl] = useState("");
  const { Text, Link } = Typography;

  const residentialAddress =
    data.residential_address_segmented.street_no +
    " " +
    data.residential_address_segmented.street_name +
    " " +
    data.residential_address_segmented.region_area +
    " " +
    data.residential_address_segmented.state +
    " " +
    data.residential_address_segmented.post_code;

  const address =
    data.legal_details.legal_address_segmented.street_no +
    " " +
    data.legal_details.legal_address_segmented.street_name +
    " " +
    data.legal_details.legal_address_segmented.region_area +
    " " +
    data.legal_details.legal_address_segmented.state +
    " " +
    data.legal_details.legal_address_segmented.post_code;

  useEffect(() => {
    getPoliceCheckImage(data && data.police_doc_url ? data.police_doc_url : "");
    getRightToWorkImage(
      data && data.right_work_doc_url ? data.right_work_doc_url : ""
    );
    getInsuranceImage(
      data && data.public_liability_insurance && data.public_liability_insurance.insurance_certificate_url
        ? data.public_liability_insurance.insurance_certificate_url
        : ""
    );
    getUserAvatarImage(data && data.avatar_url ? data.avatar_url : "");
    getUserCardIdImage(
      data && data.id_photo_card_url ? data.id_photo_card_url : ""
    );
  }, []);

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  const getRightToWorkImage = (avatar) => {
    if (avatar !== "") {
      const storage = getStorage();
      const starsRef = ref(storage, avatar);
      getDownloadURL(starsRef)
        .then((url) => {
          setRightToWorkUrl(url);
        })
        .catch((error) => {
          log(error);
        });
    }
  };

  const getUserAvatarImage = (avatar) => {
    if (avatar !== "") {
      const storage = getStorage();
      const starsRef = ref(storage, avatar);
      getDownloadURL(starsRef)
        .then((url) => {
          setAvatar(url);
        })
        .catch((error) => {
          log(error);
        });
    }
  };

  const getUserCardIdImage = (avatar) => {
    if (avatar !== "") {
      const storage = getStorage();
      const starsRef = ref(storage, avatar);
      getDownloadURL(starsRef)
        .then((url) => {
          setIdCard(url);
        })
        .catch((error) => {
          log(error);
        });
    }
  };

  const getInsuranceImage = (avatar) => {
    if (avatar !== "") {
      const storage = getStorage();
      const starsRef = ref(storage, avatar);
      getDownloadURL(starsRef)
        .then((url) => {
          setInsuranceUrl(url);
        })
        .catch((error) => {
          log(error);
        });
    }
  };

  const getPoliceCheckImage = (avatar) => {
    if (avatar !== "") {
      const storage = getStorage();
      const starsRef = ref(storage, avatar);
      getDownloadURL(starsRef)
        .then((url) => {
          setPoliceCheckUrl(url);
        })
        .catch((error) => {
          log(error);
        });
    }
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch { }
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() { },
    });
  };

  const handleServerSucessModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      let baseUrl = window.localStorage.getItem(config.riderLandingpageUrl);
      var win = window.open(baseUrl, "_self");
      win.focus();
    } catch { }
  };

  const modalSuccessInfo = (message) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk();
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    setLoading(true);
    try {
      const result = await service.documentsPortalDispute(
        agreementInput,
        ip,
        rejectInfo,
        newCertificateUrl
      );
      setLoading(false);
      log(result.data);

      switch (result.data.code) {
        case 200:
          modalSuccessInfo("Application updates received");
          break;
        case 428:
          modalErrorInfo(result.data.message);
          break;
        case 429:
          modalErrorInfo(result.data.message);
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err,
      });
      setLoading(false);
    }
  };

  function unixTimestamp(date = Date.now()) {
    return Math.floor(date / 1000);
  }

  const handleUploadFile = async (e) => {
    setLoading(true);
    const imageFile = e.target.files[0];
    const fileType = `${e.target.files[0].type}`.split("/");

    const storage = getStorage();
    const auth = getAuth();

    const timestamp = unixTimestamp();
    const url =
      "riderRejectDisputes/" +
      auth.currentUser.uid +
      "/temporary/" +
      timestamp +
      "." +
      fileType[1];

    try {
      const metadata = {
        contentType: imageFile.type,
      };
      const storageRef = ref(storage, url);
      uploadBytes(storageRef, imageFile, metadata).then(() => {
        messageApi.open({
          type: "success",
          content: "ِYour file has been uploaded successfully",
        });
        setLoading(false);
        setNewCertificateUrl(url);
      });
    } catch (error) {
      setLoading(false);
      setLoading(false);
      messageApi.open({
        type: "error",
        content: error,
      });
    }
  };
  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main className="reject-portal">
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section>
              <Typography.Title level={5} style={{ textAlign: "center" }}>
                Application summary
              </Typography.Title>
              <Row>
                <Col xs={20} sm={20} md={12} lg={12}>
                  <figure className="profile-image">
                    <img src={avatar !== "" ? avatar : profile} />
                  </figure>
                  <figcaption>Profile Photo</figcaption>
                </Col>
                <Col xs={20} sm={20} md={12} lg={12}>
                  <figure className="card-image">
                    <img src={idCard !== "" ? idCard : profile} />
                  </figure>
                  <figcaption>Phote ID</figcaption>
                </Col>
              </Row>
              <Divider
                className="reject-divider"
                orientation="left"
                style={{ margin: "40px 0", fontSize: 20, fontWeight: 900 }}
              >
                Personal Details
              </Divider>
              <Row>
                <Col xs={20} sm={20} md={6} lg={6}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      Full Name:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.given_name + " " + data.last_name}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={5} lg={5}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      DOB:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.birth_date}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={5} lg={5}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      Gender:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.gender}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={8} lg={8}>
                  <Space.Compact direction="horizental" block>
                    <Space.Compact direction="vertical" block>
                      <Button
                        className="image-button"
                        onClick={() => {
                          var win = window.open(policeCheckUrl, "_blank");
                          win.focus();
                        }}
                      >
                        <img className="certificate-img" src={shield} />
                      </Button>
                      <Text style={{ margin: "10px 0", textAlign: "center" }}>
                        Police Check
                      </Text>
                    </Space.Compact>
                    <Space.Compact direction="vertical" block>
                      <Button
                        className="image-button"
                        onClick={() => {
                          var win = window.open(rightToWorkUrl, "_blank");
                          win.focus();
                        }}
                      >
                        <img className="certificate-img" src={rightToWork} />
                      </Button>
                      <Text style={{ margin: "10px 0", textAlign: "center" }}>
                        Right to Work
                      </Text>
                    </Space.Compact>
                  </Space.Compact>
                </Col>
              </Row>
              <Row
                style={{
                  margin: "20px 0",
                }}
              >
                <Col span={24}>
                  <Space.Compact direction="horizendatl" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      Residential Address:
                    </Typography.Title>
                    <Text
                      className="reject-value"
                      style={{
                        marginLeft: 85,
                      }}
                    >
                      {residentialAddress}
                    </Text>
                  </Space.Compact>
                </Col>
              </Row>
              <Divider
                className="reject-divider"
                orientation="left"
                style={{ margin: "40px 0", fontSize: 20, fontWeight: 900 }}
              >
                Legal Details
              </Divider>
              <Row>
                <Col xs={20} sm={20} md={14} lg={14}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      Legal (Business) Name:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.legal_details.legal_name}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={5} lg={5}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      ABN:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.legal_details.abn}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={5} lg={5}>
                  <Space.Compact direction="vertical" block>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      GST:
                    </Typography.Title>
                    <Text className="reject-value" style={{ margin: "10px 0" }}>
                      {data.rider_registered_for_gst
                        ? "Registered"
                        : "Not Registered"}
                    </Text>
                  </Space.Compact>
                </Col>
                <Col xs={20} sm={20} md={24} lg={24}>
                  <Space.Compact
                    direction="horizendatl"
                    block
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                      }}
                    >
                      Legal (Business) Address:
                    </Typography.Title>
                    <Text
                      className="reject-value"
                      style={{
                        marginLeft: 40,
                      }}
                    >
                      {address}
                    </Text>
                  </Space.Compact>
                </Col>
              </Row>
              {!data.public_liability_insurance_not_provided && (
                <>
                  <Divider
                    className="reject-divider"
                    orientation="left"
                    style={{ margin: "40px 0", fontSize: 20, fontWeight: 900 }}
                  >
                    Public Liability Insurance
                  </Divider>
                  <Row>
                    <Col xs={20} sm={20} md={6} lg={6}>
                      <Space.Compact direction="vertical" block>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                          }}
                        >
                          Company:
                        </Typography.Title>
                        <Text
                          className="reject-value"
                          style={{ margin: "10px 0" }}
                        >
                          {data.public_liability_insurance.insurer_company}
                        </Text>
                      </Space.Compact>
                    </Col>
                    <Col xs={20} sm={20} md={6} lg={6}>
                      <Space.Compact direction="vertical" block>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                          }}
                        >
                          Policy Number:
                        </Typography.Title>
                        <Text
                          className="reject-value"
                          style={{ margin: "10px 0" }}
                        >
                          {data.public_liability_insurance.insurance_number
                            ? data.public_liability_insurance.insurance_number
                            : ""}
                        </Text>
                      </Space.Compact>
                    </Col>
                    <Col xs={20} sm={20} md={6} lg={6}>
                      <Space.Compact direction="vertical" block>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                          }}
                        >
                          Expiry Date:
                        </Typography.Title>
                        <Text
                          className="reject-value"
                          style={{ margin: "10px 0" }}
                        >
                          {data.public_liability_insurance_validity_date
                            ? data.public_liability_insurance_validity_date
                            : ""}
                        </Text>
                      </Space.Compact>
                    </Col>

                    <Col xs={20} sm={20} md={6} lg={6}>
                      <Space.Compact direction="vertical" block>
                        <Button
                          className="image-button"
                          onClick={() => {
                            var win = window.open(insuranceUrl, "_blank");
                            win.focus();
                          }}
                        >
                          <img className="certificate-img" src={certificate} />
                        </Button>
                        <Text style={{ margin: "10px 0", textAlign: "center" }}>
                          Certificate
                        </Text>
                      </Space.Compact>
                    </Col>
                  </Row>
                </>
              )}
            </section>
          </Col>
        </Row>
        <Row justify="center" gutter={[24, 16]}>
          <Col span={20}>
            <div className="reject-info">
              <Divider style={{ margin: "40px 0" }} />

              <p>
                If you believe that your Bearer application was incorrectly
                rejected and feel that providing additional information could
                influence our decision, you can submit your reasons and attach
                supporting documents to contest this decision. Before attaching
                any documents or submitting your dispute, please carefully read
                our Privacy Policy. Our team dedicated to these applications
                will conduct a thorough internal review of your request.
                However, we cannot provide any estimates regarding the
                processing time and cannot guarantee that the provided
                information will alter the outcome of the recent decision made
                by our verification team.
              </p>
              <textarea
                value={rejectInfo}
                onChange={(e) => setRejectInfo(e.target.value)}
              />
              <div className="upload-file">
                <Space>
                  <span>Attach supporting documents:</span>
                  <input
                    id="files"
                    type="file"
                    accept=".pdf, .png, .jpg, .jpeg"
                    onChange={handleUploadFile}
                  />
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </main>
      <div className="user-footer">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph
              className="policy-agreement"
              align="justify"
              target="_blank"
            >
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)}>
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 &&
                checked &&
                rejectInfo.trim().length > 2 && (
                  <Button
                    className="submit"
                    type="primary"
                    onClick={() => handleSubmitPage()}
                  >
                    Submit
                  </Button>
                )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Reject;
