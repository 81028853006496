import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Divider,
  message,
  Button,
  Input,
  Checkbox,
  Modal,
} from "antd";
import "./updateProfile.scss";
import UploadAvatarPhoto from "./UploadAvatarPhoto";
import UploadCardId from "./UploadCardId";
import Spinner from "../spinner/Spinner";
import * as service from "../../pages/main/mainServices";
import { getAuth } from "firebase/auth";
import config from "../../config.json";
import ServerMessage from "../ServerMessage/ServerMessage";

function UpdateProfile({ props, data, ip }) {
  const { Paragraph } = Typography;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cardIdImageUrl, setCardIdImageUrl] = useState(data.id_photo_card_url);
  const [isCardIdImageChanged, setIsCardIdImageChanged] = useState(false);
  const [agreementInput, setAgreementInput] = useState("");

  useEffect(() => { }, []);

  const updateCardUrl = (value) => {
    setCardIdImageUrl(value);
  };

  const isChangePhoto = (value) => {
    setIsCardIdImageChanged(value);
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch { }
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() { },
    });
  };

  const handleServerSucessModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      let baseUrl = window.localStorage.getItem(config.riderLandingpageUrl);
      var win = window.open(baseUrl, "_self");
      win.focus();
    } catch { }
  };

  const modalSuccessInfo = (message) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk();
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    setLoading(true);
    try {
      const result = await service.documentsPortalUpdateIdPhotoCard(
        data.avatar_url,
        cardIdImageUrl,
        isCardIdImageChanged,
        agreementInput,
        ip
      );
      setLoading(false);

      switch (result.data.code) {
        case 200:
          modalSuccessInfo("Application updates received");
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err.message ? err.message : "Something went wrong",
      });
      setLoading(false);
    }
  };

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main>
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section className="profile-update">
              <Row gutter={[24, 16]}>
                <Divider className="divider">Profile Photo</Divider>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Paragraph align="justify" className="title-info">
                    Your profile photo holds great importance in the onboarding
                    process, as it plays an important role in the{" "}
                    <span className="blue">review</span> an{" "}
                    <span className="green">approval</span> of your application
                    by the support team.
                  </Paragraph>
                </Col>
                <Col xs={24} sm={24} md={18} lg={12}>
                  <UploadAvatarPhoto avatarUrl={data.avatar_url} />
                </Col>
                <Col xs={24} sm={24} md={16} lg={12}>
                  <Card title="Important tips:" bordered={false} size="small">
                    <p>1. Be the sole subject</p>
                    <p>2. Use a recent photo</p>
                    <p>3. Make your face the focus</p>
                    <p>4. Select a simple background</p>
                    <p>5. Keep it clear and non-blurry</p>
                  </Card>
                </Col>
              </Row>
              <Row justify="center" gutter={[24, 16]}>
                <Divider className="divider">Photo ID</Divider>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Paragraph className="title-info">
                    In order to verify your profile photo, we need to have a
                    copy of your Australian Photo Identification Card. We will
                    securely keep these information on our servers.
                    <br />
                    <br />
                    There are some important guidelines to ensure a seamless
                    experience.
                    <br />
                  </Paragraph>
                </Col>
                <Col xs={18} sm={18} md={18} lg={12}>
                  <UploadCardId
                    photoIdUrl={data.id_photo_card_url}
                    updateCardUrl={updateCardUrl}
                    isChangePhoto={isChangePhoto}
                  />
                </Col>
                <Col xs={24} sm={24} md={16} lg={12}>
                  <Card
                    title="Please make sure that the file you attach is:"
                    bordered={false}
                    size="small"
                  >
                    <p>• A Valid Australian ID</p>
                    <p>• Clear and Legible to read</p>
                    <p>• Representing Accurate Information</p>
                    <p>• In a Proper Format (JPEG and PNG Only)</p>
                    <p>• Showing your ID on a Light Background</p>
                    <p>• In Landscape Mode</p>
                    <p>• Without any unnecessary Cropping or Alterations</p>
                  </Card>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </main>
      <div className="user-footer">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph className="policy-agreement" align="justify">
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)} target="_blank">
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 && checked && (
                <Button
                  className="submit"
                  type="primary"
                  onClick={() => handleSubmitPage()}
                >
                  Sign and Submit
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default UpdateProfile;
