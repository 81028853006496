import React, { useEffect, useState } from "react";
import "./main.scss";
import { Col, Row, Avatar, Button, Space, Modal, message } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import UpdateProfile from "../../components/updateProfile/UpdateProfile";
import LegalDetail from "../../components/legalDetail/LegalDetail";
import PersonalDetail from "../../components/personalDetail/PersonalDetail";
import { log } from "../../utils/helper/Utils";
import profile from "../../assets/images/profile.png";
import * as service from "./mainServices";
import Spinner from "../../components/spinner/Spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import config from "../../config.json";
import Reject from "../../components/reject/Reject";
import WhatsAppFeedBack from "../../components/whatsApp/WhatsAppFeedBack";
import FirstNccSetting from "../../components/nccSettings/FirstNccSetting";
import SecondNccSetting from "../../components/nccSettings/SecondNccSetting";
import ThirdNccSetting from "../../components/nccSettings/ThirdNccSetting";
import ForthNccSetting from "../../components/nccSettings/ForthNccSetting";
import FifthNccSetting from "../../components/nccSettings/FifthNccSetting";
import SixthNccSetting from "../../components/nccSettings/SixthNccSetting";


function Main(props) {
  const [loading, setLoading] = useState(true);
  const [bearerUserAvatar, setBearerUserAvatar] = useState("");
  const [serviceData, setServiceData] = useState();
  const [statusColor, setStatusColor] = useState("");
  const [statusText, setStatusText] = useState("");
  const [ip, setIp] = useState();
  const [messageApi, messageContextHolder] = message.useMessage();
  const storage = getStorage();

  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) {
        const response = await fetch("https://api.ipify.org/?format=json");
        if (!response.ok) {
          return modalErrorInfoLogout(
            "For legal purposes, during the registration, we need to have access to your IP address. Please turn off anti-virus software and try again."
          );
        } else {
          const data = await response.json();
          setIp(data.ip);
          getLoginPermission();
        }
      } else {
        const data = await response.json();
        setIp(data.ip);
        getLoginPermission();
      }
    } catch (err) {
      return modalErrorInfoLogout(
        "For legal purposes, during the registration, we need to have access to your IP address. Please turn off anti-virus software and try again."
      );
    }
  };
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        getIp();
      }
    });

  }, []);



  const getLoginPermission = async () => {
    try {
      const result = await service.documentsPortalLoginPermission();
      setLoading(false);
      log("*********** Main 83 ***********");
      log(result.data);
      switch (result.data.code) {
        case 200:
          setServiceData(result.data);
          const starsRef = ref(storage, result.data.avatar_url);
          if (result.data.sign_up_stage === 8) {
            setStatusColor("orange");
            switch (result.data.sign_up_sub_stage) {
              case 1:
                setStatusText("Photo ID and Avatar couldn’t been Verified");
                break;
              case 2:
                setStatusText(
                  "Legal (Business) details couldn’t been Verified"
                );
                break;
              case 3:
                setStatusText(
                  "Right to Work results and Personal details mismatch"
                );
                break;
              case 4:
                setStatusText(
                  "Police Check results and Personal details mismatch"
                );
                break;
              case 6:
                setStatusText(
                  "National Crime Check (NCC) documents couldn’t be verified"
                );
                break;
              case 5:
                setStatusText(
                  "Right to Work check expired"
                );
                break;
              default:
                setStatusText(
                  "Further actions required"
                );
                break;
            }
          } else if (result.data.sign_up_stage === 9) {
            setStatusColor("red");
            setStatusText("Application Rejected");
          }

          getDownloadURL(starsRef)
            .then((url) => {
              setBearerUserAvatar(url);
            })
            .catch((error) => {
              messageApi.open({
                type: "error",
                content: error.message ? error.message : "something went wrong",
              });
            });
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.message ? error.message : "something went wrong",
      });
      setLoading(false);
    }
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch { }
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const Reasons = {
    DOCUMENT_ISSUE_DATE: "Document Issue Date Not Accepted",
    PERSONAL_DETAILS_MISMATCH: "Personal Details Mismatch",
    INVALID_FILE: "Attached file is Wrong, Invalid or from Not Accepted Provider",
  };


  const handlePageView = (data) => {
    if (serviceData.sign_up_stage === 8) {
      switch (serviceData.sign_up_sub_stage) {
        case 1:
          return <UpdateProfile data={data} ip={ip} />;
        case 2:
          return <LegalDetail data={data} ip={ip} />;
        case 3:
          return <PersonalDetail data={data} ip={ip} />;
        case 4:
          return <PersonalDetail data={data} ip={ip} />;
        case 6:

          const notPoliceCheckAndDocIssueDate = (!serviceData.ncc_police_check_doc_verified && (serviceData.ncc_police_check_report_not_acceptable_reason === Reasons.DOCUMENT_ISSUE_DATE))
          const notPoliceCheckAndPersonalMissMatch = (!serviceData.ncc_police_check_doc_verified && (serviceData.ncc_police_check_report_not_acceptable_reason === Reasons.PERSONAL_DETAILS_MISMATCH))
          const notPoliceCheckAndInvalidFile = (!serviceData.ncc_police_check_doc_verified && (serviceData.ncc_police_check_report_not_acceptable_reason === Reasons.INVALID_FILE))

          const notRightToWorkAndDocIssueDate = (!serviceData.ncc_right_to_work_doc_verified && (serviceData.ncc_right_to_work_report_not_acceptable_reason === Reasons.DOCUMENT_ISSUE_DATE))
          const notRightToWorkAndPersonalMissMatch = (!serviceData.ncc_right_to_work_doc_verified && (serviceData.ncc_right_to_work_report_not_acceptable_reason === Reasons.PERSONAL_DETAILS_MISMATCH))
          const notRightToWorkAndInvalidFile = (!serviceData.ncc_right_to_work_doc_verified && (serviceData.ncc_right_to_work_report_not_acceptable_reason === Reasons.INVALID_FILE))

          const isRightToWorkVerified = serviceData.ncc_right_to_work_doc_verified
          const isPoliceCheckVerified = serviceData.ncc_police_check_doc_verified


          if (
            (isRightToWorkVerified && notPoliceCheckAndPersonalMissMatch) || (notRightToWorkAndPersonalMissMatch && (isPoliceCheckVerified || notPoliceCheckAndPersonalMissMatch))
          ) {
            return <FirstNccSetting data={data} ip={ip} />;
          }


          if (isRightToWorkVerified && notPoliceCheckAndDocIssueDate) {
            return <SecondNccSetting data={data} ip={ip} serviceName={config.documentsPortalNccNewPoliceCheck} />;
          }

          if (isPoliceCheckVerified && notRightToWorkAndDocIssueDate) {
            return <SecondNccSetting data={data} ip={ip} serviceName={config.documentsPortalNccNewRightToWork} />;
          }

          if (
            (
              notRightToWorkAndDocIssueDate &&
              (notPoliceCheckAndDocIssueDate || notPoliceCheckAndPersonalMissMatch || notPoliceCheckAndInvalidFile)
            ) ||
            (
              notPoliceCheckAndDocIssueDate &&
              (notRightToWorkAndPersonalMissMatch || notRightToWorkAndInvalidFile)
            )
          ) {
            return <SecondNccSetting data={data} ip={ip} serviceName={config.documentsPortalNccNewFullNccCheck} />;
          }


          if (
            (notRightToWorkAndPersonalMissMatch && notPoliceCheckAndInvalidFile)
            ||
            (notRightToWorkAndInvalidFile && notPoliceCheckAndPersonalMissMatch)
          ) {
            return <ThirdNccSetting data={data} ip={ip} />;
          }


          if (isRightToWorkVerified && notPoliceCheckAndInvalidFile) {
            return <ForthNccSetting data={data} ip={ip} />;
          }

          if (isPoliceCheckVerified && notRightToWorkAndInvalidFile) {
            return <FifthNccSetting data={data} ip={ip} />;
          }
          if (notPoliceCheckAndInvalidFile && notRightToWorkAndInvalidFile) {
            return <SixthNccSetting data={data} ip={ip} />;
          }


        default:
          break;
      }
    } else if (serviceData.sign_up_stage === 9) {
      return <Reject data={data} ip={ip} />;
    }
  };
  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      {serviceData && (
        <>
          <Header />
          <section className="home">
            <WhatsAppFeedBack />
            <div className="user-header">
              <Row justify="center" align="middle" gutter={[24, 16]}>
                <Col xs={20} sm={20} md={4} lg={4}>
                  <figure>
                    <Avatar
                      size={140}
                      icon={<UserOutlined />}
                      src={bearerUserAvatar !== "" ? bearerUserAvatar : profile}
                    />
                    <figcaption>
                      {serviceData.given_name +
                        " " +
                        serviceData.middle_name +
                        " " +
                        serviceData.last_name}
                    </figcaption>
                  </figure>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="application-status-wrapper">
                    <Space className="application-status">
                      <Space>Application Status:</Space>
                      <Space className={statusColor}>{statusText}</Space>
                    </Space>
                  </div>
                </Col>
                <Col xs={20} sm={20} md={4} lg={4}>
                  <Space className="log-out">
                    <Button
                      type="primary"
                      icon={<LogoutOutlined />}
                      onClick={handleServerModalOk}
                    >
                      Sign Out
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
            {handlePageView(serviceData)}
          </section>
          <Footer />
        </>
      )}
    </>
  );
}
export default Main;
