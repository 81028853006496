import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../../config.json";
import { getApp } from "firebase/app";

export function websiteNewsletter(email) {
  const data = { email_address: email, webpage_id: "DocumentPortal" };
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const findRider = httpsCallable(functions, config.websiteNewsletter);
  return findRider(data);
}
