import React, { useEffect, useState } from "react";
import "./legalDetail.scss";
import {
  Col,
  Row,
  message,
  Button,
  Typography,
  Checkbox,
  Input,
  Space,
  Divider,
  DatePicker,
  Modal,
} from "antd";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { FileDoneOutlined } from "@ant-design/icons";
import GoogleMapAutoComplete from "../GoogleMapAutoComplete/GoogleMapAutoComplete";
import dayjs from "dayjs";
import moment from "moment";

import { log, abnValidator } from "../../utils/helper/Utils";
import UploadCertificate from "./UploadCertificate";
import Spinner from "../spinner/Spinner";
import * as service from "../../pages/main/mainServices";
import { getAuth } from "firebase/auth";
import config from "../../config.json";
import ServerMessage from "../ServerMessage/ServerMessage";

function LegalDetail({ props, data, ip }) {
  const [legalName, setLegalName] = useState(data.legal_details.legal_name);
  const [ABN, setABN] = useState(data.legal_details.abn);
  const [GST, setGST] = useState(data.rider_registered_for_gst);
  const [addressData, setAddressData] = useState();
  const [certificate, setCeritificate] = useState(
    data.public_liability_insurance
      ? data.public_liability_insurance.insurance_certificate_url
      : ""
  );
  const [policyNumber, setPolicyNumber] = useState(
    data.public_liability_insurance
      ? data.public_liability_insurance.insurance_number
      : ""
  );
  const [insurer, setInsurer] = useState(
    data.public_liability_insurance
      ? data.public_liability_insurance.insurer_company
      : ""
  );
  const [insuranceDate, setInsuranceDate] = useState(
    data.public_liability_insurance
      ? data.public_liability_insurance_validity_date
      : ""
  );
  const storage = getStorage();
  const regexEnglishAndNumber = /^[A-Za-z0-9 ]*$/;
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notToUploadInsuranceData, setNotToUploadInsuranceData] = useState(
    data.public_liability_insurance_not_provided
  );
  const { Paragraph } = Typography;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [agreementInput, setAgreementInput] = useState("");
  const [certificateUrl, setSertificateUrl] = useState(
    data.public_liability_insurance
      ? data.public_liability_insurance.insurance_certificate_url
      : ""
  );

  useEffect(() => {
    if (certificate !== "") {
      downloadCertificateImage();
    }
  }, []);

  const downloadCertificateImage = () => {
    const starsRef = ref(storage, certificate);
    getDownloadURL(starsRef)
      .then((url) => {
        setCeritificate(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const onCheckBoxGSTChange = (e) => {
    setGST(e.target.checked);
  };

  const onNewCertificateUrl = (value) => {
    setSertificateUrl(value);
  };

  const onExpiryDataChange = (date, dateString) => {
    setInsuranceDate(dateString);
  };

  const onAddressDataChange = (value) => {
    setAddressData(value);
  };

  const modalErrorInfoLogout = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerModalOk();
      },
    });
  };

  const handleServerModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      return props.history.replace("/login");
    } catch { }
  };

  const modalErrorInfo = (message) => {
    setLoading(false);
    Modal.warning({
      title: "Oops!!!!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() { },
    });
  };

  const handleServerSucessModalOk = () => {
    try {
      const auth = getAuth();
      auth.signOut();
      let baseUrl = window.localStorage.getItem(config.riderLandingpageUrl);
      var win = window.open(baseUrl, "_self");
      win.focus();
    } catch { }
  };

  const modalSuccessInfo = (message) => {
    setLoading(false);
    Modal.success({
      title: "Done",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        handleServerSucessModalOk();
      },
      okText: "ᵔᴥᵔ",
    });
  };

  const handleSubmitPage = async () => {
    if (legalName.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "Legal name is required",
      });
      return;
    }

    if (ABN.length < 1) {
      messageApi.open({
        type: "error",
        content: "ABN is required",
      });
      return;
    } else if (!abnValidator(ABN)) {
      messageApi.open({
        type: "error",
        content:
          "The entered ABN is Not Valid! Please check the provided number and try again.",
      });
      return;
    }

    var isAuto = false;

    if (addressData !== undefined && addressData.length === 9) {
      isAuto = addressData[8];

      var state = addressData[2];
      if (state.trim() === "") {
        messageApi.open({
          type: "error",
          content: "State Type is a mandatory field",
        });
        return;
      }

      var regionArea = addressData[3];
      if (regionArea.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Area Name is a mandatory field",
        });
        return;
      }

      var streetName = addressData[4];
      if (streetName.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Street Name is a mandatory field",
        });
        return;
      }

      var streetNumber = addressData[5];
      if (streetNumber.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Street Number is a mandatory field",
        });
        return;
      }

      var postCode = addressData[7];
      if (postCode.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Post Code is a mandatory field",
        });
        return;
      }

      var streetType = addressData[6];
      if (!isAuto) {
        if (streetType.trim() === "") {
          messageApi.open({
            type: "error",
            content: "Street Type is a mandatory field",
          });
          return;
        }
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Something bad happened to address",
      });
    }

    if (!notToUploadInsuranceData) {
      if (insurer.trim().length < 2) {
        messageApi.open({
          type: "error",
          content: "Insurer is required ",
        });
        return;
      }

      if (policyNumber.trim().length < 2) {
        messageApi.open({
          type: "error",
          content: "Policy Number is required",
        });
        return;
      }

      if (insuranceDate.trim() === "") {
        messageApi.open({
          type: "error",
          content: "Insurance expiry date is required",
        });
        return;
      }

      if (certificateUrl.trim() === "") {
        messageApi.open({
          type: "error",
          content:
            "The Public Liability Insurance Certificate of Currency must be attached",
        });
        return;
      }
    }

    if (agreementInput.trim().length < 2) {
      messageApi.open({
        type: "error",
        content: "Singnature Name could not be left blank",
      });
      return;
    }
    setLoading(true);
    try {
      const result = await service.documentsPortalUpdateLegalDetails(
        legalName,
        ABN,
        addressData[1],
        addressData[0],
        addressData[5],
        addressData[4],
        addressData[6],
        addressData[3],
        addressData[2],
        addressData[7],
        "AU",
        notToUploadInsuranceData ? "" : insuranceDate,
        notToUploadInsuranceData ? "" : insurer,
        notToUploadInsuranceData ? "" : policyNumber,
        notToUploadInsuranceData ? "" : certificateUrl,
        GST,
        agreementInput,
        ip,
        notToUploadInsuranceData,
        notToUploadInsuranceData ? "" : "Public Liability Insurance Certificate"
      );
      setLoading(false);

      switch (result.data.code) {
        case 200:
          modalSuccessInfo("Application updates received");
          break;
        case 428:
          modalErrorInfo(result.data.message);
          break;
        case 429:
          modalErrorInfo(result.data.message);
          break;
        case 400:
          modalErrorInfo(result.data.message);
          break;
        default:
          modalErrorInfoLogout(result.data.message);
          break;
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err,
      });
      setLoading(false);
    }
  };

  const agreeOnChange = (e) => {
    setChecked(e.target.checked);
  };

  const disabledDate = (current) => {
    // Disable dates before two months from now
    return current && current < moment().add(2, "months").startOf("day");
  };

  const onChangeCheckboxInsuranceDoc = (e) => {
    setNotToUploadInsuranceData(e.target.checked);
  };

  return (
    <>
      {messageContextHolder}
      {loading && <Spinner />}
      <main>
        <Row justify="center" gutter={[24, 16]}>
          <Col xs={20} sm={20} md={8} lg={8}>
            <ServerMessage data={data} />
          </Col>
          <Col xs={20} sm={20} md={12} lg={12}>
            <section className="legal-detail">
              <Row gutter={[24, 16]}>
                <Divider className="divider">Legal (Business) Details</Divider>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>Legal (Business) Name:</Typography.Text>
                    <Input
                      size="large"
                      status={legalName.trim() === "" && "error"}
                      value={legalName}
                      onChange={(e) => {
                        if (regexEnglishAndNumber.test(e.target.value)) {
                          setLegalName(e.target.value.toUpperCase());
                        }
                      }}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Typography.Text>ABN:</Typography.Text>
                    <Input
                      size="large"
                      status={abnValidator(ABN) ? null : "error"}
                      value={ABN}
                      type="number"
                      onChange={(e) => {
                        setABN(e.target.value);
                      }}
                    />
                  </Space.Compact>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Space.Compact
                    size="large"
                    direction="vertical"
                    block
                    className="componnet-wrapper"
                  >
                    <Checkbox onChange={onCheckBoxGSTChange} checked={GST}>
                      Registered for GST
                    </Checkbox>
                  </Space.Compact>
                </Col>
                <GoogleMapAutoComplete
                  data={data.legal_details.legal_address_segmented}
                  label="Legal (Business) Address:"
                  onAddressDataChange={onAddressDataChange}
                />
              </Row>
              <Row gutter={[24, 16]}>
                <Divider className="divider inside">
                  Public Liability Cover
                </Divider>
                <Col span={24}>
                  <Typography.Text style={{ textAlign: "justify" }}>
                    {data.public_liability_insurance_not_provided
                      ? "We strongly recommend sharing your Public Liability cover information. If you'd like to provide these details, please deselect the checkbox below, enter the specifics of your Public Liability Cover in the form, and attach the certificate of currency."
                      : "We highly advise providing your Public Liability cover details. However, if you choose not to, please check the box below to acknowledge the clauses in the Contractors' agreement pertaining to your obligations concerning Public Liability."}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <div
                    className="insurance-doc-checkbox"
                    style={{
                      backgroundColor: "#FFAAAA",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <Checkbox
                      onChange={onChangeCheckboxInsuranceDoc}
                      checked={notToUploadInsuranceData}
                    />
                    <Typography.Text>
                      {" "}
                      I choose not to submit my Public Liability Insurance
                      details at this time. I acknowledge and understand the
                      terms related to public liability as stipulated in the{" "}
                      <a
                        href={localStorage.getItem(config.riderTcUrl)}
                        className="public-liability-insurance-detail-anker"
                        target="_blank"
                        style={{ color: "#FF4800" }}
                      >
                        Bearer Contractor Agreement
                      </a>
                      , specifically in clauses 24.b, 26.a, and 27.a.iv.
                    </Typography.Text>
                  </div>
                </Col>
                {!notToUploadInsuranceData && (
                  <>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Space.Compact
                        size="large"
                        direction="vertical"
                        block
                        className="componnet-wrapper"
                      >
                        <Typography.Text>Insurer:</Typography.Text>
                        <Input
                          size="large"
                          status={insurer.trim() === "" && "error"}
                          value={insurer}
                          onChange={(e) => {
                            setInsurer(e.target.value);
                          }}
                        />
                      </Space.Compact>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Space.Compact
                        size="large"
                        direction="vertical"
                        block
                        className="componnet-wrapper"
                      >
                        <Typography.Text>Policy Number:</Typography.Text>
                        <Input
                          size="large"
                          status={policyNumber.trim() === "" && "error"}
                          value={policyNumber}
                          onChange={(e) => {
                            setPolicyNumber(e.target.value.toUpperCase());
                          }}
                        />
                      </Space.Compact>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Space.Compact
                        size="large"
                        direction="vertical"
                        block
                        className="componnet-wrapper"
                      >
                        <Typography.Text>Expiry Date:</Typography.Text>
                        <DatePicker
                          allowClear={false}
                          onChange={onExpiryDataChange}
                          disabledDate={disabledDate}
                          defaultValue={
                            insuranceDate && dayjs(insuranceDate, "YYYY-MM-DD")
                          }
                        />
                      </Space.Compact>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Space
                        size="large"
                        direction="vertical"
                        className="componnet-wrapper"
                      >
                        <Typography.Text>
                          Certificate of currency:
                        </Typography.Text>
                        <Row gutter={[24, 16]}>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <UploadCertificate
                              onNewCertificateUrl={onNewCertificateUrl}
                            />
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            {certificate !== "" && (
                              <Button
                                icon={<FileDoneOutlined />}
                                onClick={() => {
                                  var win = window.open(certificate, "_blank");
                                  win.focus();
                                }}
                              >
                                View Current File
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Space>
                    </Col>
                  </>
                )}
              </Row>
            </section>
          </Col>
        </Row>
      </main>
      <div className="user-footer">
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col span={18}>
            <Divider></Divider>
          </Col>
          <Col span={18}>
            <Checkbox
              className="policy-agreement-check-box"
              onChange={agreeOnChange}
              checked={checked}
            />
            <Paragraph className="policy-agreement" align="justify">
              I’ve read and agreed{" "}
              <a href={localStorage.getItem(config.riderPpUrl)} target="_blank">
                Bearer Privacy Policy
              </a>{" "}
              and{" "}
              <a href={localStorage.getItem(config.riderTcUrl)} target="_blank">
                Bearers Contractor Agreement
              </a>{" "}
              and I,
              <Input
                value={agreementInput}
                className="agreement-name"
                onChange={(e) => setAgreementInput(e.target.value)}
              />{" "}
              hereby declare, all of the information I have provided is complete
              and correct. I am aware that missing or incomplete information,
              whether deliberate or the result of negligence, may either result
              in rejection of my application or [Bearer] account(s) suspension
              for further investigations and legal actions. Also I understand
              that submitting this application will not guarantee that it would
              be accepted and will not mean that I have been accepted to work as
              a Bearer Service Provider or being allowed by the platform to
              access the applications (Mobile and Web) designed and deemed to be
              used by Bearer Service Providers (Bearers).
              <br />
              <br />
              The Electronic Transactions (Victoria) Act 2000 establishes the
              regulatory framework for transactions to be completed
              electronically. By submitting this application, you have indicated
              your approval of the contents of this electronic communication.
            </Paragraph>
          </Col>
          <Col span={18}>
            <div className="submit-btn">
              {agreementInput.length > 2 && checked && (
                <Button
                  className="submit"
                  type="primary"
                  onClick={() => handleSubmitPage()}
                >
                  Sign and Submit
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default LegalDetail;
