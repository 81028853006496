import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

import become from "../../assets/images/Becoming-Courier.png";
import speedy from "../../assets/images/Speedy-Bearer.png";
import logo from "../../assets/images/bearer-logo.svg";
import notice from "../../assets/images/requirements-notice.svg";
import wallet from "../../assets/images/wallet.svg";
import instagram from "../../assets/images/instagram.svg";
import twitter from "../../assets/images/twitter.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import "./login.scss";
import LoginFrom from "./LoginForm";
import { getAuth, signOut } from "firebase/auth";
import config from "../../config.json";

function Login(props) {
  let envBaseUrl = window.localStorage.getItem(config.bearerWebsiteBaseUrl);
  useEffect(() => {
    try {
      const auth = getAuth();
      signOut(auth)
    } catch { }
  }, []);

  const didLogin = () => {
    return props.history.replace("/home");
  };

  return (
    <div className="login">
      <div className="login-page-social-media">
        <ul>
          <li>
            <a href="https://www.instagram.com/Bearer.AU" target="_blank">
              <img src={instagram} alt="instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/Bearer.AU" target="_blank">
              <img src={facebook} alt="facebook" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Bearer_AU" target="_blank">
              <img src={twitter} alt="twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/bearer-au"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </li>
        </ul>
      </div>
      <Row justify="center">
        <Col xs={24} md={24} lg={10} xl={8}>
          <div className="left-wrapper">
            <div className="login-info-wrapper">
              <img src={logo} alt="logo" />
              <h1>Become a Bearer to start your impressive journey.</h1>
              <h6>
                Increase your earnings by filling your spare time in between
                your delivery jobs!
                <br />
                Bearers can complete jobs by riding a motorbike, cycling or even
                on-foot during their daily exercise.
              </h6>

              <div className="news-info">
                <ul>
                  <li>
                    <a
                      href={
                        envBaseUrl + "/Blog/Bearer-delivery-services-explained"
                      }
                      target="_blank"
                    >
                      <div className="thumbnail">
                        <img src={become} alt="bearer" />
                        <h2>What is Bearer?</h2>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        envBaseUrl +
                        "/Blog/Discover-the-hidden-benefits-of-becoming-a-Bearer"
                      }
                      target="_blank"
                    >
                      <div className="thumbnail">
                        <img src={speedy} alt="bearer" />
                        <h2>
                          Discover the hidden benefits of becoming a Bearer
                        </h2>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="support">
                Need to share something with us?
                <a href={envBaseUrl + "/Contact"} target="_blank">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} md={24} lg={14} xl={16}>
          <div className="main-login-right">
            <div className="menu">
              <ul>
                <li>
                  <a href={envBaseUrl} target="_blank">
                    Bearer Home
                  </a>
                </li>
                .
                <li>
                  <a href={envBaseUrl + "/About"} target="_blank">
                    About Us
                  </a>
                </li>
                .
                <li>
                  <a
                    href={envBaseUrl + "/FAQs?userType=bearers"}
                    target="_blank"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
            <div className="title">Bearers’ user portal</div>
            <div className="login-wrapper-form">
              <LoginFrom onApprove={didLogin} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Login;
