import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../../config.json";
import { log } from "../../utils/helper/Utils";
import { getApp } from "firebase/app";


export function documentsPortalLoginPermission() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const motorbike = httpsCallable(
    functions,
    config.documentsPortalLoginPermission
  );
  return motorbike();
}

export function documentsPortalUpdateIdPhotoCard(
  avatarUrl,
  cardUrl,
  isCardUploaded,
  signedName,
  ip
) {
  let data = {
    avatar_url: avatarUrl,
    id_photo_card_url: cardUrl,
    is_id_photo_card_uploaded: isCardUploaded,
    name_as_signed: signedName,
    ip: ip,
  };
  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateIdPhotoCard = httpsCallable(
    functions,
    config.documentsPortalUpdateIdPhotoCard
  );
  return documentsPortalUpdateIdPhotoCard(data);
}

export function documentsPortalUpdateLegalDetails(
  legalName,
  abn,
  apt,
  unit,
  streetNo,
  streetName,
  streetType,
  regionArea,
  state,
  postCode,
  country,
  insuranceValidityDate,
  insurerCompany,
  insuranceNumber,
  certificateUrl,
  gst,
  nameAsSigned,
  ip,
  noInsuranceFile,
  insuranceType
) {
  let data = {
    legal_name: legalName,
    abn: abn,
    legal_address_segmented: {
      apt_no: apt,
      unit_no: unit,
      street_no: streetNo,
      street_name: streetName,
      street_type: streetType,
      region_area: regionArea,
      state: state,
      post_code: postCode,
      country: country,
    },

    public_liability_insurance_validity_date: insuranceValidityDate,
    public_liability_insurer_company: insurerCompany,
    public_liability_insurance_number: insuranceNumber,
    public_liability_insurance_certificate_url: certificateUrl,
    rider_registered_for_gst: gst,
    name_as_signed: nameAsSigned,
    ip: ip,
    public_liability_insurance_not_provided: noInsuranceFile,
    public_liability_insurance_type: insuranceType,
  };
  log(data);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateLegalDetails = httpsCallable(
    functions,
    config.documentsPortalUpdateLegalDetails
  );
  return documentsPortalUpdateLegalDetails(data);
}

export function documentsPortalUpdateNCC(
  givenName,
  lastName,
  middleName,
  birthDate,
  gender,
  apt,
  unit,
  streetNo,
  streetName,
  streetType,
  regionArea,
  state,
  postCode,
  country,
  nameAsSigned,
  ip
) {
  let data = {
    given_name: givenName,
    last_name: lastName,
    middle_name: middleName,
    birth_date: birthDate,
    gender: gender,
    residential_address_segmented: {
      apt_no: apt,
      unit_no: unit,
      street_no: streetNo,
      street_name: streetName,
      street_type: streetType,
      region_area: regionArea,
      state: state,
      post_code: postCode,
      country: country,
    },
    name_as_signed: nameAsSigned,
    ip: ip,
  };
  log(data);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    config.documentsPortalUpdateNCC
  );
  return documentsPortalUpdateNCC(data);
}

export function documentsPortalDispute(
  nameAsSigned,
  ip,
  messageText,
  documentURL
) {
  let data = {
    name_as_signed: nameAsSigned,
    ip: ip,
    riders_rejection_dispute_message_text: messageText,
    riders_rejection_dispute_document_url: documentURL,
  };
  log(data);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentPortalDispute = httpsCallable(
    functions,
    config.documentsPortalDispute
  );
  return documentPortalDispute(data);
}

export function documentsPortalNccNameUpdate(
  givenName,
  lastName,
  middleName,
  birthDate,
  nameAsSigned,
  ip
) {
  let data = {
    given_name: givenName,
    last_name: lastName,
    middle_name: middleName,
    birth_date: birthDate,
    name_as_signed: nameAsSigned,
    ip: ip,
  };
  log(data);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    config.documentsPortalNccNameUpdate
  );
  return documentsPortalUpdateNCC(data);
}

export function documentsPortalNccNameUpdateAndNewFullNccCheck(
  givenName,
  lastName,
  middleName,
  birthDate,
  nameAsSigned,
  ip
) {
  let data = {
    given_name: givenName,
    last_name: lastName,
    middle_name: middleName,
    birth_date: birthDate,
    name_as_signed: nameAsSigned,
    ip: ip,
  };
  log(data);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    config.documentsPortalNccNameUpdateAndNewFullNccCheck
  );
  return documentsPortalUpdateNCC(data);
}

export function documentsPortalNccJoinedService(nameAsSigned, ip, functionName) {
  let data = { name_as_signed: nameAsSigned, ip: ip };
  log(data);
  log(functionName);
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(functions, functionName);
  return documentsPortalUpdateNCC(data);
}

export function UploadPoliceCheckOrNccNewPoliceCheck(
  firstCode,
  secondCode,
  url,
  docDateIssue,
  nameAsSigned,
  ip,
  functionName
) {

  var data = {}
  if (functionName === config.documentsPortalNccUploadPoliceCheck) {
    data = {
      police_check_issue_date: docDateIssue,
      police_check_verification_code_1: firstCode,
      police_check_verification_code_2: secondCode,
      police_check_file_url: url,
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  } else {
    data = {
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  }
  log(data);
  log(functionName);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    functionName
  );
  return documentsPortalUpdateNCC(data);
}

export function UploadRightToWorkOrNccNewRightToWork(
  firstCode,
  secondCode,
  url,
  docDateIssue,
  nameAsSigned,
  ip,
  functionName
) {

  var data = {}
  if (functionName === config.documentsPortalNccUploadRightToWork) {
    data = {
      police_check_issue_date: docDateIssue,
      police_check_verification_code_1: firstCode,
      police_check_verification_code_2: secondCode,
      police_check_file_url: url,
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  } else {
    data = {
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  }
  log(data);
  log(functionName);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    functionName
  );
  return documentsPortalUpdateNCC(data);
}

export function documentsPortalNccNewFullNccCheckOrUploadBothChecks(policeFirstCode, policeSecondCode, policeUrl, policeDocDateIssue, rightToWorkFirstCode, rightToWorkSecondCode, rightToWorkUrl, rightToWorkDocDateIssue, nameAsSigned, ip, functionName) {

  var data = {}
  if (functionName === config.documentsPortalNccUploadBothChecks) {
    data = {
      police_check_issue_date: policeDocDateIssue,
      police_check_verification_code_1: policeFirstCode,
      police_check_verification_code_2: policeSecondCode,
      police_check_file_url: policeUrl,
      right_to_work_issue_date: rightToWorkDocDateIssue,
      right_to_work_verification_code_1: rightToWorkFirstCode,
      right_to_work_verification_code_2: rightToWorkSecondCode,
      right_to_work_file_url: rightToWorkUrl,
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  } else {
    data = {
      name_as_signed: nameAsSigned,
      ip: ip,
    }
  }
  log(data);
  log(functionName);

  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    functionName
  );
  return documentsPortalUpdateNCC(data);
}