import React, { useEffect, useState } from "react";
import { message, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";
import Spinner from "../spinner/Spinner";

const UploadCertificate = ({ onNewCertificateUrl }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const storage = getStorage();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => { }, []);

  const props = {
    onChange: (file) => {
      UploadCertificate(file);
    },
    showUploadList: false,
  };

  function unixTimestamp(date = Date.now()) {
    return Math.floor(date / 1000);
  }

  const UploadCertificate = async (info) => {
    setLoading(true);
    const timestamp = unixTimestamp();
    const fileType = `${info.file.type}`.split("/");
    const imageUrl =
      "publicLiabilityInsurance/" +
      auth.currentUser.uid +
      "/temporary/" +
      timestamp +
      "-IPC." +
      fileType[1];
    const metadata = {
      contentType: info.file.type,
    };
    try {
      const imageFile = info.file.originFileObj;
      const storageRef = ref(storage, imageUrl);
      uploadBytes(storageRef, imageFile, metadata).then(() => {
        messageApi.open({
          type: "success",
          content: "ِYour file has been uploaded successfully",
        });
        onNewCertificateUrl(imageUrl);
        setLoading(false);
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      {loading && <Spinner />}
      <Upload {...props} maxCount={1} accept=".pdf, .png, .jpg, .jpeg">
        <Button icon={<UploadOutlined />}>Upload New File</Button>
      </Upload>
    </>
  );
};

export default UploadCertificate;
