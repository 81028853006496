import "./serverMessage.scss";
import { Typography } from "antd";

function ServerMessage({ props, data }) {
  const { Paragraph } = Typography;
  const reason =
    data.public_liability_insurance &&
      data.public_liability_insurance.invalidity_reason
      ? data.public_liability_insurance.invalidity_reason
      : "";



  const handleAdminMessage = () => {

    if (data.sign_up_stage === 8) {
      switch (data.sign_up_sub_stage) {
        case 6:
          return <>
            <Paragraph className="bearer-info" style={{ whiteSpace: "pre-line" }}>
              {!data.ncc_police_check_doc_verified && <>
                <h4 className="bearer-ncc-note-title">
                  Police Check Report issues:
                </h4>
                <p>{data.ncc_police_check_review_result_note}</p>
              </>
              }
              {!data.ncc_right_to_work_doc_verified && <>
                <h4 className="bearer-ncc-note-title">
                  Right to Work Report issues:
                </h4>
                <p>{data.ncc_right_to_work_review_result_note}</p>
              </>
              }
            </Paragraph>

          </>
        case 2:
          return <>
            <Paragraph className="bearer-info" style={{ whiteSpace: "pre-line" }}>
              {data.sign_up_documents_review_result_note}
            </Paragraph>
            reason !== "" && <>
              <h4 className="bearer-info-title">Public Liability Insurance</h4>
              <Paragraph className="bearer-info">{reason}</Paragraph>
            </>
          </>

        default:
          return <Paragraph className="bearer-info" style={{ whiteSpace: "pre-line" }}>
            {data.sign_up_documents_review_result_note}
          </Paragraph>
      }

    }
  }

  return (
    <>
      <h3 className="bearer-info-title">Message from bearer support team</h3>
      <>{handleAdminMessage()}</>

    </>
  );
}

export default ServerMessage;
